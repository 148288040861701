import './App.css';
import React, { useState, useEffect } from 'react';

const myHeaders = new Headers();
myHeaders.append("Accept", "application/json");
myHeaders.append("Connection", "keep-alive");
myHeaders.append("X-API-KEY", "142fc60b-391c-4867-afae-3d95ce8fd4b6");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

const MenuItem = ({ title, price, description, /* thumbnail, */ model }) => (
  <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-8">
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold">{title}</h3>
        <span className="text-lg ms-0 font-bold">{price}</span>
      </div>
      <p className="text-sm mt-2">{description}</p>
    </div>
    <div className="flex mt-4 lg:mt-0 space-x-4">
      {/* {thumbnail && (
        <div className="relative">
          <img src={`https://api.arfoodz.com${thumbnail}`} className="rounded-full w-20 m-3 object-cover md:w-40 md:h-40 lg:w-48 lg:h-48" alt="food item" />
        </div>
      )} */}
      {model && (
        <div className="lg:ml-10">
          <model-viewer
            id="viewer"
            src={`https://api.arfoodz.com${model}`}
            ios-src=""
            shadow-intensity="1"
            ar
            camera-controls
            touch-action="pan-y"
          >
            <button slot="ar-button" id="ar-button" className='bg-white text-black w-auto pl-5 pr-5 rounded'>
              View in AR
            </button>
          </model-viewer>
        </div>
      )}
    </div>
  </div>
);

const BurgerMenu = () => {
  const [filters, setFilters] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("https://api.arfoodz.com/api/categories", requestOptions)
      .then(response => response.json())
      .then(data => data.categories)
      .then(categories => {
        const fetchFilterPromises = categories.map(category => {
          return fetch(`https://api.arfoodz.com/api/filters?categoryId=${category.id}`, requestOptions)
            .then(response => response.json())
            .then(data => ({ category: category.name, filters: data.filters }));
        });
        return Promise.all(fetchFilterPromises);
      })
      .then(allFilters => {
        setFilters(allFilters);
      })
      .catch(error => {
        setError(error);
        console.error("Error fetching data: ", error);
      });
  }, []);

  return (
    <>
      {error && <div>Error: {error.message}</div>}
      {!filters.length && <div>Loading...</div>}
      {filters.length > 0 && (
        <div className="absolute w-full h-auto bg-black text-white p-6 md:p-8 lg:p-12">
          <div className="flex justify-between items-center mb-6">
            <div className="flex flex-col start-0 space-y-2">
              <img src="./Imgs/BurgerImg.png" alt="burger logo" className="w-20 object-contain" />
              <span className="text-xl lg:text-7xl font-bold">Burger Menu</span>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {filters.map((filterGroup, index) => (
              <div key={index} className="space-y-10">
                <div>
                  <h2 className="text-4xl font-bold text-yellow-500">{filterGroup.category}</h2>
                  <div className="mt-4 space-y-2">
                    {filterGroup.filters.map((filter, idx) => (
                      <MenuItem
                        key={idx}
                        title={filter.name}
                        description={filter.shortDescription}
                        price={`$ ${filter.price}`}
                        // thumbnail={filter.thumbnailFilePath}
                        model={filter.modelFilePath}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="relative w-full lg:w-auto lg:bottom-0 start-0 mt-8 flex justify-evenly lg:justify-start lg:ml-10 items-center">
            <div className="text-sm">
              <p>WWW.yourwebsite.com</p>
              <div className="flex space-x-2 mt-2">
                <img src="https://placehold.co/20x20" alt="social icon" className="w-5 h-5" />
                <img src="https://placehold.co/20x20" alt="social icon" className="w-5 h-5" />
                <img src="https://placehold.co/20x20" alt="social icon" className="w-5 h-5" />
                <img src="https://placehold.co/20x20" alt="social icon" className="w-5 h-5" />
              </div>
            </div>
            <div className="bg-yellow-500 text-black rounded-full p-4 text-center lg:ml-20">
              <p className="font-bold">FREE DELIVERY</p>
              <p className="font-bold">+22 456 878</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BurgerMenu;
